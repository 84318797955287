import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import Content from 'components/category/content'
import AdditionalResources from 'components/new/additional-resources'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import FeaturesGrid from 'components/features-grid'
import { graphql, Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const items = [
  {
    link: '/lawn-garden/gators/mid-size-crossover-gators/',
    imageId: 'midSizeImage',
    name: 'Mid-Size Crossover Series',
    alt: 'John Deere Mid-Size Crossover Series Gators',
  },
  {
    link: '/lawn-garden/gators/full-size-crossover-gators/',
    imageId: 'fullSizeImage',
    name: 'Full-Size Crossover Series',
    alt: 'John Deere Full-Size Crossover Series Gators',
  },
  {
    link: '/lawn-garden/gators/work-series-gators/',
    imageId: 'workSeriesImage',
    name: 'Work Series',
    alt: 'John Deere Work Series Gators',
  },
  // {
  //   link: '/lawn-garden/gators/attachments/',
  //   imageId: 'gatorAttachmentsImage',
  //   name: 'Gator Attachments',
  //   alt: 'John Deere Gator with attachments',
  // },
]

const GatorsPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, gatorsFallBlogPostImage, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Gators | Hutson Inc</title>
        <meta
          name='description'
          content='Find John Deere Gators for sale at the best prices. Compare the works series, mid-size and full-size crossovers and see which on fits your lifestyle the best.'
        />
        <meta name='keywords' content='Gator, UTV, Side by Side, Utility Vehicle, XUV, HPX, SXS' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Lawn & Garden Equipment',
                'item': 'https://www.hutsoninc.com/lawn-garden/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Gators',
                'item': 'https://www.hutsoninc.com/lawn-garden/gators/',
              },
            ],
          })}
        </script>
      </Helmet>
      <HeroGradient image={heroImage} heading='Gators' />
      <Content>
        <Floater items={gridItems} />

        <Copy header='John Deere Gators'>
          <p>
            Hauling. Fishing. Fencing. As sure as real work requires breaking a sweat, Gator Utility
            Vehicles are engineered to work as tough and play as hard as you do. Hutson has the
            line-up to prove it! These hardworking UTVs are some of the best, and most versatile in
            the market. From the high load capacity of the{' '}
            <Link to='/lawn-garden/gators/work-series-gators/'>Work Series</Link> to the versatility
            of the <Link to='/lawn-garden/gators/mid-size-crossover-gators/'>Mid-Size</Link> and{' '}
            <Link to='/lawn-garden/gators/full-size-crossover-gators/'>Full-Size</Link> Crossover
            Series. Not ready for something new? We have a large selection of{' '}
            <Link to='/used-equipment/utvs-and-atvs/gators-and-atvs/'>used UTVs</Link> that may suit
            your needs.
          </p>
        </Copy>
      </Content>
      <FeaturesGrid
        features={[
          {
            image: gatorsFallBlogPostImage.childImageSharp.gatsbyImageData,
            imageAlt: 'Men driving a John Deere XUV Series Gator',
            title: 'Getting the most out of John Deere Gators',
            copy: 'Gators are built to be tough and versatile for handling whatever you throw at them. Want to put one to the test? Check out this blog post to learn more ways to use a Gator and help reach its full potential.',
            button: {
              link: '/blog/fall-gators-guide/',
              text: 'Read the post on getting the most out of John Deere Gators',
            },
          },
        ]}
      />
      <Content>
        <Promos data={promos} type='Gator' />
        <Videos type='Gator' id='q9XeTe9UVdk' list='PLUz5fj7f_mw-oRy1CxQmvdqGPfc_UYjWR' />
      </Content>
      <AdditionalResources
        resources={[
          // {
          //   title: 'Shopping Tools',
          //   links: [<OutboundLink href='https://build.hutsoninc.com/gators/'>Build Your Own</OutboundLink>],
          // },
          {
            title: 'Product Brochures',
            links: [
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=bc26aa79'>
                View Mid-Size Crossover UTVs Brochure
              </OutboundLink>,
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=9905a7ab'>
                View Full-Size Crossover UTVs Brochure
              </OutboundLink>,
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=bc26ae6b'>
                View Work Series Gators Brochure
              </OutboundLink>,
            ],
          },
          {
            title: 'Offers & Discounts',
            links: [<Link to='/promotions/john-deere/gators/'>View Current Promotions</Link>],
          },
        ]}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query gatorsSubcategoryQuery($subcategory: String = "gators") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    gatorsFallBlogPostImage: file(
      relativePath: { eq: "lawn-and-garden/gators-fall-blog-post.jpg" }
    ) {
      ...SharpImage900
    }
    heroImage: file(relativePath: { eq: "headers/gators-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    workSeriesImage: file(relativePath: { eq: "lawn-and-garden/work-series-gators.jpg" }) {
      ...FloatingGridImage
    }
    midSizeImage: file(relativePath: { eq: "lawn-and-garden/mid-size-crossover-gators.jpg" }) {
      ...FloatingGridImage
    }
    fullSizeImage: file(relativePath: { eq: "lawn-and-garden/full-size-crossover-gators.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default GatorsPage
